<template>
  <PageWithLayout>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import { GET_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@/utils/urlUtils';

export default {
  name: 'WorldUpdateLanding',
  components:{
    PageWithLayout
  },
  mounted(){
    this.init();
  },
  methods:{
    init(){
      const query = {
        type: 'CONTENTS_FAKE_MAP'
      }
      const path = `${this.$apiPath.WORLDMAP_FEATURE_FLAG}${makeQueryStringByObject(query)}`;
      GET_REQUEST(path).then(
      (success) => {
        const resultData = success.data.body;
        // true면 살아있는 이벤트
        // false면 종료된 이벤트
        if(resultData.property){
          // 소개페이지로 이동
          this.$router.push({name:'WorldUpdateIntroduce'});
        }else{
          // 종료페이지로 이동
          this.$router.push({name:'WorldUpdateEnd'});
        }
      }, (failed) => {
        this.$store.dispatch('commonToast/fetchToastStart', failed.msg);
      })
    },
  }
}
</script>